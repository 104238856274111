// https://ant.design/docs/react/replace-moment-cn#DatePicker

import generatePicker from 'antd/es/date-picker/generatePicker'
import { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import 'antd/es/date-picker/style/index'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'

dayjs.extend(advancedFormat)
dayjs.extend(localeData)
dayjs.extend(weekday)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.extend(customParseFormat)
dayjs.extend(utc)

export const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig as any)

export default DatePicker
