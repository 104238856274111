/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type CloudconfigConfigurationChangeTargetType = typeof CloudconfigConfigurationChangeTargetType[keyof typeof CloudconfigConfigurationChangeTargetType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CloudconfigConfigurationChangeTargetType = {
  cluster: 'cluster',
  tenant_init: 'tenant_init',
  template: 'template',
} as const;
