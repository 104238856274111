import { Input, Table, Button, Space, Select, Form } from 'antd'
import { useState, useEffect } from 'react'

import { cloudConfigControllerListParameterAttributes } from 'apiClient/services/devops'
import { CloudconfigParameterAttribute } from 'apiClient/services/devops/interface'
import { parseFormValue } from 'pages/Tenants/ClusterDetail/Configs/CurrentConfig/Current'
import { convertToString } from 'pages/Tenants/ClusterDetail/Configs/Forms/PreviewForm'

const { TextArea } = Input

interface ParameterManagerProps {
  configType: string
  version: string
  selectedParams: Record<string, any>
  onParameterChange: (name: string, value: any) => void
  onParameterRemove: (name: string) => void
}

const ParameterManager = ({
  configType,
  version,
  selectedParams,
  onParameterChange,
  onParameterRemove
}: ParameterManagerProps) => {
  const [availableParams, setAvailableParams] = useState<
    CloudconfigParameterAttribute[]
  >([])
  const [loading, setLoading] = useState(false)
  const [editingKey, setEditingKey] = useState('')

  useEffect(() => {
    loadParameters()
  }, [configType, version])

  const loadParameters = async () => {
    setLoading(true)
    try {
      const res = await cloudConfigControllerListParameterAttributes(
        configType,
        version
      )
      setAvailableParams(res.data.items || [])
    } catch (error) {
      console.error('Failed to load parameters:', error)
    }
    setLoading(false)
  }

  const getNextParameterNumber = () => {
    const existingNumbers = Object.keys(selectedParams)
      .map((key) => parseInt(key, 10))
      .filter((num) => !isNaN(num))
    return existingNumbers.length > 0 ? Math.max(...existingNumbers) + 1 : 1
  }

  const columns = [
    {
      title: 'Parameter',
      dataIndex: 'name',
      key: 'name',
      render: (_: any, record: any) => {
        const isEditing = record.name === editingKey

        if (configType === 'INIT_SQL') {
          if (record.name === 'NEW' && !isEditing) {
            return (
              <Button
                type="link"
                onClick={() => {
                  const newParamName = `${getNextParameterNumber()}`
                  onParameterChange(newParamName, { value: '', type: 'string' })
                }}
              >
                Add Paragraph
              </Button>
            )
          }
          return 'Paragraph_' + record.name
        }

        if (record.name === 'NEW' && !isEditing) {
          return (
            <Button type="link" onClick={() => setEditingKey(record.name)}>
              Add Parameter
            </Button>
          )
        }
        if (isEditing) {
          return (
            <Select
              style={{ width: '100%' }}
              showSearch
              placeholder="Select parameter"
              options={availableParams
                .filter((p) => !selectedParams[p.name!])
                .map((p) => ({
                  label: p.name || '',
                  value: p.name || ''
                }))}
              onChange={(value, option: any) => {
                onParameterChange(value as string, {
                  value: option.default,
                  type: option.type
                })
                setEditingKey('')
              }}
              filterOption={(input, option) =>
                (option?.label as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          )
        }
        return record.name
      }
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (_: any, record: any) => {
        if (record.name === 'NEW') {
          return null
        }

        if (configType === 'INIT_SQL') {
          return (
            <TextArea
              value={convertToString(record.value)}
              onChange={(e) =>
                onParameterChange(record.name, {
                  value: e.target.value,
                  type: 'string'
                })
              }
              autoSize={{ minRows: 3, maxRows: 10 }}
              style={{ minWidth: '300px' }}
            />
          )
        }

        const param = availableParams.find((p) => p.name === record.name)
        return param?.type === 'bool' ? (
          <Select
            value={String(record.value)}
            onChange={(value) =>
              onParameterChange(record.name, {
                value: value === 'true',
                type: param.type
              })
            }
            options={[
              { label: 'true', value: 'true' },
              { label: 'false', value: 'false' }
            ]}
          />
        ) : (
          <Input
            placeholder='"" is not required for string. NOT support long int'
            value={convertToString(record.value)}
            onChange={(e) =>
              onParameterChange(record.name, {
                value: parseFormValue(param?.type, e.target.value),
                type: param?.type
              })
            }
          />
        )
      }
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_: any, record: any) => {
        if (configType === 'INIT_SQL') {
          return 'string'
        }
        const param = availableParams.find((p) => p.name === record.name)
        return param?.type || ''
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: any) => (
        <Space>
          {record.name !== 'NEW' && (
            <Button
              type="link"
              danger
              onClick={() => onParameterRemove(record.name)}
            >
              Delete
            </Button>
          )}
        </Space>
      )
    }
  ]

  const tableData = [
    ...Object.entries(selectedParams).map(([name, value]) => ({
      name,
      value: value.value,
      type: value.type,
      key: name
    })),
    { name: 'NEW', value: null, type: null, key: 'NEW' }
  ]

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={false}
      size="small"
    />
  )
}

export default ParameterManager
