import { Card } from 'antd'
import { useState } from 'react'

import TenantInit from './DefaultTemplate'
import DefaultTemplate from './DefaultTemplate'
import TemplateLists from './TemplateLists'

function ConfigTemplates() {
  const [activeTabKey, setActiveTabKey] = useState<string>('default')
  const onTabChange = (key: string) => {
    setActiveTabKey(key)
  }
  const tabList = [
    {
      key: 'default',
      tab: 'Default Templates'
    },
    {
      key: 'templates',
      tab: 'Custom Templates'
    }
  ]
  const contentList: Record<string, React.ReactNode> = {
    templates: <TemplateLists />,
    default: <DefaultTemplate></DefaultTemplate>
  }
  return (
    <div>
      <Card
        tabList={tabList}
        tabProps={{ size: 'middle' }}
        bordered={false}
        activeTabKey={activeTabKey}
        onTabChange={(key) => {
          onTabChange(key)
        }}
      >
        {contentList[activeTabKey]}
      </Card>
    </div>
  )
}

export default ConfigTemplates
