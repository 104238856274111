import { Button, Input, Modal, Select, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useEffect, useMemo, useState, useCallback } from 'react'
import { Link, useParams } from 'react-router-dom'

import { cloudConfigControllerListTemplates } from 'apiClient/services/devops'
import {
  CloudConfigControllerListTemplatesClusterType,
  CloudConfigControllerListTemplatesParams,
  CloudconfigTemplate
} from 'apiClient/services/devops/interface'
import { ListToolBar, useTable } from 'components/Table'

import CreateTemplateForm from '../components/CreateTemplateForm'

function TemplateLists() {
  const [idName, setIDName] = useState<string>()
  const [createTemplate, setCreateTemplate] = useState<boolean>(false)

  const [listTemplateParam, setListTemplateParam] =
    useState<CloudConfigControllerListTemplatesParams>({
      id_in: undefined,
      name_in: undefined,
      cluster_type:
        CloudConfigControllerListTemplatesClusterType.DEDICATED_TIDB,
      template_type: 'custom',
      version: undefined
    })
  const getData = (params?: Partial<PageInfo>) =>
    cloudConfigControllerListTemplates(listTemplateParam)
  const { dataSource, loading, reload, paginationProps, onTableChange } =
    useTable<ListData<CloudconfigTemplate>>(getData, [], {
      pagination: true
    })
  const columns: ColumnsType<CloudconfigTemplate> = [
    {
      title: 'ID',
      render: (_: any, record) => {
        return record.id
      }
    },
    {
      title: 'Name',
      render: (_: any, record) => {
        return record.name
      }
    },
    {
      title: 'Effective Version',
      render: (_: any, record) => {
        return record.tags?.effective_minor_version
      }
    },
    {
      title: 'Config Type',
      render: (_: any, record) => {
        return record.config_type
      }
    },
    {
      title: 'Creator',
      render: (_: any, record) => {
        return record.tags?.creator_email
      }
    },
    {
      title: 'Action',
      render: (_: any, record) => {
        return (
          <div>
            <Button type="primary">
              <Link to={`/template/${record.id}`}>Detail</Link>
            </Button>
          </div>
        )
      }
    }
  ]

  const handleCloseModal = useCallback(() => {
    setCreateTemplate(false)
  }, [])

  const actions = useMemo(
    () => [
      <Select
        options={[
          //{ value: 'ID', label: 'ID' },
          { value: 'version', label: 'Version' }
        ]}
        onChange={(e) => {
          setIDName(String(e))
        }}
        placeholder="Choose Filter"
      ></Select>,
      <Input
        onChange={(e) => {
          if (idName === 'ID') {
            setListTemplateParam((pre) => ({
              ...pre,
              id_in: [e.target.value],
              name_in: undefined
            }))
          } else if (idName === 'version') {
            setListTemplateParam((pre) => ({
              ...pre,
              version: e.target.value,
              id_in: undefined
            }))
          } else {
            setListTemplateParam((pre) => ({
              ...pre,
              name_in: undefined,
              id_in: undefined
            }))
          }
        }}
      ></Input>,
      <Button
        type="primary"
        onClick={() => {
          reload()
        }}
      >
        Search
      </Button>,
      <Button
        type="primary"
        onClick={() => {
          setCreateTemplate(true)
        }}
      >
        Add Template
      </Button>
    ],
    [listTemplateParam, idName]
  )
  return (
    <>
      <div>
        <ListToolBar
          actions={actions}
          reload={() => {
            return reload()
          }}
        ></ListToolBar>
        <br></br>
        <Table<CloudconfigTemplate>
          columns={columns}
          rowKey="id"
          dataSource={dataSource}
          loading={loading}
          pagination={paginationProps}
          onChange={onTableChange}
          size="small"
        />
      </div>
      <Modal
        visible={createTemplate}
        title="Create Template"
        footer={null}
        onCancel={handleCloseModal}
        width={1000}
      >
        <CreateTemplateForm
          onSuccess={() => {
            handleCloseModal()
            reload()
          }}
          onCancel={handleCloseModal}
        />
      </Modal>
    </>
  )
}

export default TemplateLists
