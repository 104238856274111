import { Form, FormInstance, InputNumber, Select } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'

import { FormsBindedSupportPlan } from 'apiClient/services/billing/interface'
import DatePicker from 'components/DatePicker'
import {
  PlanLevels,
  SupportPlanCostType,
  TieredPriceField,
  formatSupportPlanType
} from 'pages/Tenants/TenantDetail/BusinessInfo/SupportPlan'

export const SupportPlanField = ({
  readOnly,
  publicPlans,
  onLevelChange,
  form
}: {
  readOnly: boolean
  publicPlans?: Record<string, FormsBindedSupportPlan>
  onLevelChange?: (value: string) => void
  form: FormInstance
}) => {
  const [selectedLevel, setSelectedLevel] = useState<string>(
    PlanLevels.ENTERPRISE
  )
  const [selectedType, setSelectedType] = useState<string>(
    SupportPlanCostType.PERCENTAGE_PRICE
  )

  const disabeldDate = (date: Dayjs) => {
    const end = dayjs().add(1, 'day').utc().startOf('day')
    return dayjs(date).utc().startOf('day').isBefore(end)
  }

  useEffect(() => {
    if (publicPlans && selectedLevel) {
      const type =
        publicPlans[selectedLevel]?.type || SupportPlanCostType.PERCENTAGE_PRICE
      form.setFieldsValue({
        support_plan: {
          type
        }
      })
      setSelectedType(type)
    }
  }, [selectedLevel, publicPlans, form])

  const plans = [
    PlanLevels.DEVELOPER,
    PlanLevels.ENTERPRISE,
    PlanLevels.PREMIUM
  ]

  return (
    <>
      <Form.Item
        name={['support_plan', 'level']}
        label="Support Plan Level"
        rules={[{ required: true, message: 'Required' }]}
      >
        <Select
          onChange={(value: string) => {
            setSelectedLevel(value)
            onLevelChange?.(value)
          }}
        >
          {plans.map((pl) => (
            <Select.Option key={pl} value={pl || ''}>
              {pl}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={['support_plan', 'type']}
        label="Support Plan Type"
        rules={[{ required: true, message: 'Required' }]}
      >
        <Select
          disabled={readOnly}
          onChange={(value) => setSelectedType(value as string)}
        >
          {[
            SupportPlanCostType.FIXED_PRICE,
            SupportPlanCostType.PERCENTAGE_PRICE,
            SupportPlanCostType.TIERED_PRICE
          ].map((pl) => (
            <Select.Option key={pl} value={pl || ''}>
              {formatSupportPlanType(pl)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Minimum cost per month basis" required>
        <Form.Item
          name={['support_plan', 'fixed_charge_price']}
          noStyle
          rules={[{ required: true, message: 'Required' }]}
        >
          <InputNumber
            disabled={readOnly}
            min="0"
            stringMode
            step="0.01"
            style={{ display: 'inline-block', width: '90%' }}
          ></InputNumber>
        </Form.Item>
        <span> $</span>
      </Form.Item>
      {selectedType === SupportPlanCostType.PERCENTAGE_PRICE && (
        <Form.Item label="Percentage of monthly usage" required>
          <Form.Item
            name={['support_plan', 'support_price_percentage']}
            noStyle
            rules={[{ required: true, message: 'Required' }]}
          >
            <InputNumber<string>
              stringMode
              min="0"
              max="100"
              step="0.01"
              disabled={readOnly}
              style={{ display: 'inline-block', width: '90%' }}
            ></InputNumber>
          </Form.Item>
          <span> %</span>
        </Form.Item>
      )}
      {selectedType === SupportPlanCostType.TIERED_PRICE && (
        <TieredPriceField
          form={form}
          name={['support_plan', 'tiered_price_rules']}
        />
      )}
      <Form.Item
        label="End Date"
        name={['support_plan', 'expired_date']}
        rules={[
          ({ getFieldValue }) => ({
            validator(rule, value: Dayjs) {
              if (!value) {
                return Promise.resolve()
              }
              const startDate = getFieldValue(['effected_at']) as Dayjs
              if (!startDate) {
                return Promise.reject('Please fill Effective Date')
              }

              const start = dayjs(startDate).utc().startOf('day')
              const end = dayjs(value).utc().startOf('day')
              if (!end.isAfter(start)) {
                return Promise.reject(
                  new Error('End Date should be greater than Effective Date.')
                )
              }
              return Promise.resolve()
            }
          })
        ]}
      >
        <DatePicker
          showTime={false}
          style={{ width: '100%' }}
          disabledDate={disabeldDate}
          disabled={readOnly}
        />
      </Form.Item>
    </>
  )
}
