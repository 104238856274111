import { Card, Descriptions, Divider, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { template } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'

import { cloudConfigControllerGetTemplate } from 'apiClient/services/devops'
import {
  CloudConfigControllerGetTemplateClusterType,
  CloudconfigComponentDefaultCase,
  CloudconfigNamedParameter,
  CloudconfigTemplate,
  CloudconfigTemplateDefaultCase,
  CloudconfigTemplateParameters
} from 'apiClient/services/devops/interface'

const ConfigTemplateDetail = () => {
  const { template_id } = useParams<{
    template_id: string
  }>()
  const [template, setTemplate] = useState<CloudconfigTemplate>()
  // const [usage, setUsage] = useState<CloudconfigConfigurationTemplateUsage>()
  const [reload, setReload] = useState(true)

  useEffect(() => {
    fetch()
  }, [reload])

  async function fetch() {
    try {
      const data = (
        await cloudConfigControllerGetTemplate(template_id, {
          cluster_type:
            CloudConfigControllerGetTemplateClusterType.DEDICATED_TIDB,
          with_default_cases: true
        })
      ).data
      setTemplate(data.template)
    } catch (e) {
      console.error(e)
    }
  }

  const [activeTabKey, setActiveTabKey] = useState<string>('defaultFor')

  const tabList = [
    {
      key: 'defaultFor',
      tab: 'Default For'
    }
    // {
    //   key: 'usedBy',
    //   tab: 'Used By Clusters'
    // }
  ]

  const columnsDefault: ColumnsType<CloudconfigTemplateDefaultCase> = useMemo(
    () => [
      {
        title: 'Version',
        render: (_: any, record) => {
          return record.version
        }
      },
      {
        title: 'Cloud Provider',
        render: (_: any, record) => {
          return record.cloud_provider
        }
      },
      {
        title: 'Instance Type',
        render: (_: any, record) => {
          return String(record.instance_type)
        }
      }
    ],
    [template]
  )

  const columns: ColumnsType<UnifyParam> = useMemo(
    () => [
      {
        title: 'Name',
        render: (_: any, record) => {
          return record.param.name
        }
      },
      {
        title: 'Value',
        render: (_: any, record) => {
          return String(record.param.value)
        }
      },
      {
        title: 'Comment',
        render: (_: any, record) => {
          return record.param.comment
        }
      }
    ],
    [template]
  )

  /*
  const columnsCluster: ColumnsType<{ cluster_id: string }> = useMemo(
    () => [
      {
        title: 'Cluster ID',
        render: (_: any, record) => {
          return record.cluster_id
        }
      }
    ],
    []
  )
    */

  const contentList: Record<string, React.ReactNode> = {
    defaultFor: (
      <Table dataSource={template?.default_cases} columns={columnsDefault} />
    )
    // usedBy: <Table
    //   dataSource={usage?.cluster_id?.map(v => { return { cluster_id: v } })}
    //   columns={columnsCluster}
    // />
  }

  var configTypeList = []
  for (const key in template?.custom_template_info?.data) {
    configTypeList.push({
      key: key,
      tab: key
    })
  }
  const [activeTabKey0, setActiveTabKey0] = useState<string>('tidb') // todo: dynamic default

  function genParamsList(key: string) {
    if (configTypeList.length === 0) {
      return <div>Empty Template</div>
    }

    return (
      <Table
        style={{ whiteSpace: 'pre-wrap' }}
        dataSource={mapToList(
          template?.custom_template_info?.data?.[key]?.parameters,
          template?.custom_template_info?.data?.[key]?.config_type
        )}
        columns={columns}
        pagination={{ pageSize: 30 }}
        key="name"
      />
    )
  }

  if (template?.tags?.template_type == 'custom') {
    return (
      <Card bordered={false}>
        <Descriptions title="Template Infomation">
          <Descriptions.Item label="ID">{template_id}</Descriptions.Item>
          <Descriptions.Item label="Name">{template?.name}</Descriptions.Item>
          <Descriptions.Item label="Config Type">
            {template?.config_type}
          </Descriptions.Item>
        </Descriptions>
        <Divider></Divider>
        <h4>Template Parameters</h4>
        <Card
          tabList={configTypeList}
          tabProps={{ size: 'middle' }}
          bordered={true}
          onTabChange={setActiveTabKey0}
        >
          {genParamsList(activeTabKey0)}
        </Card>
        <Divider></Divider>
      </Card>
    )
  }

  return (
    <Card bordered={false}>
      <Descriptions title="Template Infomation">
        <Descriptions.Item label="ID">{template_id}</Descriptions.Item>
        <Descriptions.Item label="Name">{template?.name}</Descriptions.Item>
        <Descriptions.Item label="Config Type">
          {template?.config_type}
        </Descriptions.Item>
      </Descriptions>
      <Divider></Divider>
      <h4>Template Parameters</h4>
      <Table
        style={{ whiteSpace: 'pre-wrap' }}
        dataSource={mapToList(template?.parameters, template?.config_type)}
        columns={columns}
        pagination={{ pageSize: 30 }}
      />
      <Divider></Divider>
      <h4>Usage</h4>
      <Card
        tabList={tabList}
        tabProps={{ size: 'middle' }}
        bordered={false}
        activeTabKey={activeTabKey}
        onTabChange={setActiveTabKey}
      >
        {contentList[activeTabKey]}
      </Card>
    </Card>
  )
}

export type UnifyParam = {
  key: string
  filename: string
  param: CloudconfigNamedParameter
}
const mapToList = (
  m: CloudconfigTemplateParameters | undefined,
  filename?: string | undefined
) => {
  if (m && filename) {
    var l: UnifyParam[] = []
    Object.keys(m).forEach((k) => {
      const p = m[k]
      l.push({ param: p, filename, key: p.name! })
    })
    return l
  }
  return []
}

export default ConfigTemplateDetail
