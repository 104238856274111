import {
  Form,
  Input,
  Select,
  Button,
  Space,
  Card,
  Tabs,
  Modal,
  Alert
} from 'antd'
import { useState, useEffect } from 'react'

import {
  cloudConfigControllerCreateConfigurationTemplateChange,
  cloudConfigControllerListParameterVersions
} from 'apiClient/services/devops'
import {
  CloudconfigChangeFileReq,
  CloudconfigCreateConfigurationTemplateChangeReq,
  CloudConfigControllerListTemplatesClusterType,
  CloudconfigParameterApplyPolicy
} from 'apiClient/services/devops/interface'

import ParameterManager from './ParameterManager'

const CONFIG_TYPES = ['tidb', 'tikv', 'tiflash', 'INIT_SQL']

export interface TemplateFormValues {
  name: string
  version: string
  files: CloudconfigChangeFileReq[]
}

interface CreateTemplateFormProps {
  onSuccess: () => void
  onCancel: () => void
}

const CreateTemplateForm = ({
  onSuccess,
  onCancel
}: CreateTemplateFormProps) => {
  const [form] = Form.useForm<TemplateFormValues>()
  const [currentConfigType, setCurrentConfigType] = useState('tidb')
  const [versions, setVersions] = useState<string[]>([])
  const [parameters, setParameters] = useState<
    Record<string, Record<string, any>>
  >({
    tidb: {},
    tikv: {},
    tiflash: {},
    INIT_SQL: {}
  })
  const [selectedVersion, setSelectedVersion] = useState('')

  // Initialize form with empty files array
  useEffect(() => {
    const fetchVersions = async () => {
      try {
        const resp = await cloudConfigControllerListParameterVersions()
        const versionList = resp.data.versions || []
        setVersions(versionList)
      } catch (error) {
        console.error('Failed to fetch versions:', error)
      }
    }
    fetchVersions()
    form.setFieldsValue({ files: [] })
  }, [form])

  const handleParameterChange = (type: string, name: string, value: any) => {
    setParameters((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [name]: value
      }
    }))

    // Get current files from form
    const files = form.getFieldValue('files') || []
    const fileIndex = files.findIndex((f: any) => f.config_type === type)

    if (fileIndex === -1) {
      // Create new file entry if it doesn't exist
      form.setFieldsValue({
        files: [
          ...files,
          {
            filename: type,
            config_type: type,
            custom_parameters: [
              {
                name,
                value: value.value
              }
            ]
          }
        ]
      })
    } else {
      // Update existing file entry
      const updatedFiles = [...files]
      const paramIndex = updatedFiles[fileIndex].custom_parameters.findIndex(
        (p: any) => p.name === name
      )

      if (paramIndex === -1) {
        updatedFiles[fileIndex].custom_parameters.push({
          name,
          value: value.value
        })
      } else {
        updatedFiles[fileIndex].custom_parameters[paramIndex].value =
          value.value
      }

      form.setFieldsValue({ files: updatedFiles })
    }
  }

  const handleParameterRemove = (type: string, name: string) => {
    setParameters((prev) => {
      const newParams = { ...prev[type] }
      delete newParams[name]
      return { ...prev, [type]: newParams }
    })

    // Update form values
    const files = form.getFieldValue('files') || []
    const fileIndex = files.findIndex((f: any) => f.config_type === type)
    if (fileIndex !== -1) {
      files[fileIndex].custom_parameters = files[
        fileIndex
      ].custom_parameters.filter((p: any) => p.name !== name)
      form.setFieldsValue({ files })
    }
  }

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()
      const req: CloudconfigCreateConfigurationTemplateChangeReq = {
        cluster_type:
          CloudConfigControllerListTemplatesClusterType.DEDICATED_TIDB,
        apply_policy: CloudconfigParameterApplyPolicy.RollingRestart,
        files: values.files,
        version: {
          to_version: values.version
        },
        reason: values.name
      }

      const resp = await cloudConfigControllerCreateConfigurationTemplateChange(
        req
      )

      Modal.success({
        title: 'Success',
        content: 'Template create request submitted successfully',
        onOk: () => {
          onSuccess()
          window.open(`/confighistories/${resp.data.change_id}`, '_blank')
        }
      })
    } catch (error) {
      Modal.error({
        title: 'Error',
        content: error.response?.data?.errmsg || 'Failed to create template'
      })
    }
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={(changedValues) => {
        if (changedValues.hasOwnProperty('version')) {
          setSelectedVersion(changedValues.version)
        }
      }}
    >
      <Form.Item name="files" hidden>
        <Input />
      </Form.Item>
      <Space direction="vertical" style={{ width: '100%' }} size="large">
        <Card>
          <Form.Item
            name="name"
            label="Template Name"
            rules={[{ required: true }]}
          >
            <Input placeholder="Enter template name" />
          </Form.Item>

          <Form.Item
            name="version"
            label="Version"
            rules={[{ required: true }]}
          >
            <Select options={versions.map((v) => ({ label: v, value: v }))} />
          </Form.Item>
        </Card>

        <Card title="Configuration Parameters">
          <Tabs
            type="card"
            activeKey={currentConfigType}
            onChange={setCurrentConfigType}
          >
            {CONFIG_TYPES.map((type) => (
              <Tabs.TabPane tab={type.toUpperCase()} key={type}>
                {selectedVersion ? (
                  <ParameterManager
                    configType={type}
                    version={selectedVersion}
                    selectedParams={parameters[type]}
                    onParameterChange={(name, value) =>
                      handleParameterChange(type, name, value)
                    }
                    onParameterRemove={(name) =>
                      handleParameterRemove(type, name)
                    }
                  />
                ) : (
                  <Alert
                    message="Please select a version first"
                    type="info"
                    showIcon
                  />
                )}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Card>
        <div style={{ marginTop: 24, textAlign: 'right' }}>
          <Space>
            <Button onClick={onCancel}>Cancel</Button>
            <Button type="primary" onClick={handleSubmit}>
              Create
            </Button>
          </Space>
        </div>
      </Space>
    </Form>
  )
}

export default CreateTemplateForm
