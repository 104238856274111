import { Descriptions, Divider, Form, Input, Modal, Table, Alert } from 'antd'
import { Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { ColumnsType } from 'antd/lib/table'
import Title from 'antd/lib/typography/Title'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { getCluster, getTenant, useGetCluster } from 'apiClient/services/devops'
import {
  CloudconfigApplyConfigurationChangeReq,
  CloudconfigChangedParameter,
  CloudconfigConfigurationComponentChange,
  CloudconfigConfigurationFileChange,
  CloudconfigParameterApplyPolicy,
  CloudconfigPreviewConfigurationChangeResp,
  ClusterGetClusterResp,
  TenantGetTenantResp
} from 'apiClient/services/devops/interface'

export type ModifiedTable = {
  param?: CloudconfigChangedParameter
  filename?: string
}

export type PreviewFormType = {
  apply_policy?: CloudconfigParameterApplyPolicy
  reason: string
}

export const columnsModified: ColumnsType<ModifiedTable> = [
  {
    title: 'Filename',
    render: (_: any, record) => {
      return record.filename
    }
  },
  {
    title: 'Name',
    render: (_: any, record) => {
      return record.param?.name
    }
  },
  {
    title: 'Source',
    render: (_: any, record) => {
      return `${String(record.param?.from_source)} -> ${String(
        record.param?.to_source
      )}`
    }
  },
  {
    title: 'Previous Value',
    render: (_: any, record) => {
      return convertToString(record.param?.from)
    }
  },
  {
    title: 'New Value',
    render: (_: any, record) => {
      return convertToString(record.param?.to)
    }
  }
]

const PreviewForm = ({
  preview,
  type
}: {
  preview?: CloudconfigPreviewConfigurationChangeResp
  type: string
}) => {
  const { cluster_id, org_id } = useParams<{
    cluster_id: string
    org_id: string
  }>()
  const [tenantInfo, setTenantInfo] = useState<TenantGetTenantResp>()
  const [clusterInfo, setClusterInfo] = useState<ClusterGetClusterResp>()
  const [selectedApplyPolicy, setSelectedApplyPolicy] = useState<string>()

  useEffect(() => {
    fetch()
  }, [])

  async function fetch() {
    try {
      const tenant = (await getTenant(org_id)).data
      setTenantInfo(tenant)
      if (cluster_id) {
        const cluster = (await getCluster(cluster_id)).data
        setClusterInfo(cluster)
      }
    } catch (error) {
      Modal.error({
        title: error.response.data.errcode,
        content: error.response.data.errmsg
      })
    }
  }

  const columnsComponents: ColumnsType<CloudconfigConfigurationComponentChange> =
    [
      {
        title: 'Component',
        render: (_: any, record) => {
          return record.component
        }
      },
      {
        title: 'Modified Image Tag',
        render: (_: any, record) => {
          return `${String(record.modified_image_tag?.from)} -> ${String(
            record.modified_image_tag?.to
          )}`
        }
      }
    ]

  return (
    <>
      {preview?.change?.scope?.version !== null ? (
        <div>
          <Descriptions title="Version Modification Info">
            <Descriptions.Item label="From Version">
              <b>{preview?.change?.scope?.version!.from}</b>
              <b></b>
            </Descriptions.Item>
            <Descriptions.Item label="To Version">
              <b>{preview?.change?.scope?.version!.to}</b>
            </Descriptions.Item>
          </Descriptions>
        </div>
      ) : (
        <></>
      )}

      {preview?.change?.scope?.components !== null ? (
        <div>
          <Title level={5}>Cluster Image Modification Info</Title>
          <Table
            dataSource={preview?.change?.scope?.components?.filter((v) => {
              return v.modified_image_tag !== null
            })}
            columns={columnsComponents}
            pagination={false}
          ></Table>
          <Divider></Divider>
        </div>
      ) : (
        <></>
      )}

      <Title level={5}>Modified Configs Table</Title>
      <Table
        style={{ marginTop: '10px' }}
        dataSource={FlatFiles(preview?.change?.scope?.files || [])}
        columns={columnsModified}
        pagination={false}
      />
      <Divider></Divider>

      <Title level={5}>Required Options</Title>
      {type === 'cluster' || type === 'upgrade' ? (
        <div>
          <Form.Item
            name="apply_policy"
            label="Apply Policy"
            style={{ marginLeft: 0 }}
            labelCol={{ span: 0 }}
            required={true}
          >
            <Select
              options={preview?.apply_policies
                ?.sort((a, b) => {
                  return SortApplyPolicy(a, b)
                })
                .map((p) => {
                  return {
                    value: p,
                    label: p
                  }
                })}
              style={{ width: '30%', marginTop: '10px' }}
              onChange={(value) => {
                setSelectedApplyPolicy(value?.toString())
              }}
            ></Select>
          </Form.Item>
        </div>
      ) : (
        <></>
      )}
      <Form.Item
        name="reason"
        label="Reason"
        rules={[{ required: true }]}
        style={{ marginLeft: 0 }}
        labelCol={{ span: 0 }}
      >
        <TextArea></TextArea>
      </Form.Item>
      {selectedApplyPolicy ===
        CloudconfigParameterApplyPolicy.EnsureUpdateToTC && (
        <Alert
          message="WARNING"
          description={
            <span>
              The Apply Policy <b>'EnsureUpdateToTC'</b> is special and only
              used by{' '}
              <a
                href="https://pingcap.feishu.cn/wiki/YJc2wxAgPi4R2Vkepedc1crGnfb"
                target="_blank"
                rel="noopener noreferrer"
              >
                Shutdown Operation
              </a>
              , please make sure you known it means.
            </span>
          }
          type="warning"
          showIcon
        />
      )}
    </>
  )
}

export const FlatFiles = (fs: CloudconfigConfigurationFileChange[]) => {
  var res: ModifiedTable[] = []
  fs.forEach((v) => {
    v.modified_parameters?.forEach((p) => {
      res.push({ filename: v.filename, param: p })
    })
  })
  return res
}

export function convertToString(input: unknown): string {
  if (input === null) {
    return '-'
  }
  if (
    typeof input === 'string' ||
    typeof input === 'number' ||
    typeof input === 'boolean'
  ) {
    return input.toString()
  } else {
    return JSON.stringify(input)
  }
}

export function SortApplyPolicy(
  a: CloudconfigParameterApplyPolicy,
  b: CloudconfigParameterApplyPolicy
): number {
  const prio = new Map<CloudconfigParameterApplyPolicy, number>([
    [CloudconfigParameterApplyPolicy.RollingRestart, 10],
    [CloudconfigParameterApplyPolicy.HotReload, 9],
    [CloudconfigParameterApplyPolicy.EnsureUpdateToTC, 8]
  ])

  const prioA = prio.get(a) || 0
  const prioB = prio.get(b) || 0
  return prioB - prioA
}

export default PreviewForm
